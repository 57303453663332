<div class="ios-popup Modal-Background max-width-550">
    <div class="ios-header">
        <img class="logo" src="../../../assets/logo.png" alt="11exch">
        <svg (click)="closeClicked()" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25"
            fill="none">
            <path
                d="M6.22578 19.3251L5.17578 18.2751L10.9508 12.5L5.17578 6.72505L6.22578 5.67505L12.0008 11.45L17.7758 5.67505L18.8258 6.72505L13.0508 12.5L18.8258 18.2751L17.7758 19.3251L12.0008 13.55L6.22578 19.3251Z"
                fill="#B6BECD" />
        </svg>
    </div>
    <div class="ios-back">
        <svg (click)="closeClicked()" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
            fill="none">
            <path
                d="M13.1213 17.0761L6.25 10.2047L13.1213 3.33337L14.0833 4.31254L8.19115 10.2047L14.0833 16.0969L13.1213 17.0761Z"
                fill="#003c79" />
        </svg>
        <span>{{'iOS'|translate}}</span>
    </div>
    <span class="ios">{{'iOS Instruction'|translate}}</span>
    <div class="ios-card">
        <div class="ios-card-1">
            <div class="first-card">
                <div class="one">
                    <span>1</span>
                </div>
                <div class="one-text">
                    <span>{{'Open in Safari'|translate}}</span>
                    <small>{{"If you are in any other browser, open this page in the Safari browser"|translate}}</small>
                </div>
            </div>
            <div class="first-card">
                <div class="one">
                    <span>2</span>
                </div>
                <div class="one-text">
                    <span>{{'Click “Share”'|translate}}</span>
                    <small>{{'Tap on the “Share” icon in the menu at the bottom of the screen. A dialog box will
                        open'|translate}}</small>
                </div>
            </div>
            <div class="first-card">
                <div class="one">
                    <span>3</span>
                </div>
                <div class="one-text">
                    <span>{{'Click “Go to Home Screen”'|translate}}</span>
                    <small>{{'Tap on the item “On the Home screen”, and click “Finish”'|translate}}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="ex-card">
        <div class="exch-card">
            <div class="exch-header">
                <img class="logo" src="../../../assets/logo.png" alt="11exch">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                    <path
                        d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476617 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3166 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C21.9966 8.08367 20.8365 5.28778 18.7744 3.22563C16.7122 1.16347 13.9163 0.00344047 11 0ZM15.242 13.829C15.3375 13.9212 15.4137 14.0316 15.4661 14.1536C15.5185 14.2756 15.5461 14.4068 15.5473 14.5396C15.5484 14.6724 15.5231 14.8041 15.4728 14.927C15.4225 15.0499 15.3483 15.1615 15.2544 15.2554C15.1605 15.3493 15.0489 15.4235 14.926 15.4738C14.8031 15.5241 14.6714 15.5494 14.5386 15.5483C14.4058 15.5471 14.2746 15.5195 14.1526 15.4671C14.0306 15.4147 13.9203 15.3385 13.828 15.243L11 12.414L8.172 15.243C7.9834 15.4252 7.7308 15.526 7.4686 15.5237C7.20641 15.5214 6.95559 15.4162 6.77019 15.2308C6.58478 15.0454 6.47961 14.7946 6.47733 14.5324C6.47505 14.2702 6.57585 14.0176 6.758 13.829L9.58601 11L6.758 8.171C6.66249 8.07875 6.58631 7.96841 6.5339 7.8464C6.48149 7.7244 6.45391 7.59318 6.45275 7.4604C6.4516 7.32762 6.4769 7.19594 6.52718 7.07305C6.57746 6.95015 6.65172 6.8385 6.74561 6.74461C6.8395 6.65071 6.95115 6.57646 7.07405 6.52618C7.19695 6.4759 7.32863 6.4506 7.46141 6.45175C7.59418 6.4529 7.72541 6.48049 7.84741 6.5329C7.96941 6.58531 8.07976 6.66149 8.172 6.757L11 9.586L13.828 6.757C13.9203 6.66149 14.0306 6.58531 14.1526 6.5329C14.2746 6.48049 14.4058 6.4529 14.5386 6.45175C14.6714 6.4506 14.8031 6.4759 14.926 6.52618C15.0489 6.57646 15.1605 6.65071 15.2544 6.74461C15.3483 6.8385 15.4225 6.95015 15.4728 7.07305C15.5231 7.19594 15.5484 7.32762 15.5473 7.4604C15.5461 7.59318 15.5185 7.7244 15.4661 7.8464C15.4137 7.96841 15.3375 8.07875 15.242 8.171L12.414 11L15.242 13.829Z"
                        fill="#B6BECD" />
                </svg>
            </div>
        </div>
    </div>
</div>