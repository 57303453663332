<div class="shadow">
    <div class="card" [style.height.vh]="fullView ? 100 : 60">
        <div (click)="hide()" class="close">
            X
        </div>
        <br>
        <div class="container" style="padding-top: 30px;">
            <!-- <h2><b>500%</b> <span class="light-txt"> Deposit Bonus upto </span> </h2> -->
            <!-- <h2> {{this._bonus.showHowTo.promoDetails['infoHead']}} </h2> -->
            <!-- <h2 [innerHTML]="sanitizeHead"> </h2> -->

            <app-frame-box>
                <br>
                <h1> <span style="font-size: xx-large; color: #22C55E; font-weight: 400; ">{{'5%
                        extra'|translate}}</span> {{'on every Deposit No wagering No conditions'|translate}}</h1>
                <!-- <h1 class="green-bold">Todo</h1> -->
                <div class="row">
                    <div class="col-6">
                        <div class="iconbox">
                            <img loading="lazy" class="iconslogo" style="margin: 0"
                                src="../../../../assets/images/Frame 1410144257.svg" alt="">
                            <div class="subbox">
                                <p style="color:#7E8A98; white-space: nowrap;">{{'Min deposit:'|translate}}</p>
                                <p>₹300</p>
                                <!-- <p>₹{{this._bonus.showHowTo.promoDetails['minDeposit']}}</p> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="iconbox">
                            <img loading="lazy" class="iconslogo" style="margin: 0"
                                src="../../../../assets/images/time (6) 1.svg" alt="">
                            <div class="subbox">
                                <p style="color:#7E8A98; white-space: nowrap;">{{'Expiry:'|translate}}</p>
                                <p>{{'No'|translate}}</p>
                                <!-- <p>{{this._bonus.showHowTo.promoDetails['validity']}} Days</p> -->
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-12" style="text-align: left;">
                        <div class="iconbox">
                            <img loading="lazy" class="iconslogo" style="margin: 0" src="../../../../assets/images/withdraw (1) 1.svg"
                                alt="">
                            <div class="subbox">
                                <p style="color:#7E8A98; white-space: nowrap;">Maximum Winning:</p>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-12 leftalign">
                        <p style="font-size: initial;
                        text-align: center;
                        margin: 10px;"> <b style="color: #22C55E;">11%</b> {{"of your deposit directly goes to your
                            main wallet"|translate}} <span style="color: #EAB308;">{{'immediately'|translate}}</span>
                        </p>
                        <!-- <p class="yellotxt">To get the bonus in main wallet:</p>
                        <p>
                            Play on Sportsbook and Match odds markets with odds from 1.40 to 3.5 for 30 times of bonus
                            amount.
                        </p> -->
                    </div>
                </div>


                <button class="btn btn-success" style="margin-top: 10px;" *ngIf="!fullView" (click)="ViewMore()">{{'View
                    Details'|translate}}</button>
            </app-frame-box>

            <div *ngIf="fullView">
                <div style="margin-top: 50px; height: 700px;">

                    <h1 style="font-weight: 600;">
                        <!-- {{this._bonus.showHowTo.promoDetails['short-info']}} -->
                    </h1>
                    <!-- <h1 style="font-weight: 600;">
                        <span style="font-size: larger; color: green;">500%</span> Deposit bonus up to <span
                            style="font-size: x-large; color: gold;">₹50,000</span>
                    </h1> -->

                    <h2>{{'This offer is available on first deposit'|translate}}</h2>
                    <p style="
                    color: #7E8A98;
                    font-size: large;
                    ">{{'No Wagering required'|translate}}</p>

                    <button class="btn btn-success" style="margin: 20px auto;">{{'Get the bonus'|translate}}</button>

                    <h1>{{'How to claim the bonus?'|translate}}</h1>

                    <p style=" font-size: larger;">{{'Make a Deposit and Get'|translate}} <b
                            style="color: #22C55E;">{{'11% free'|translate}}</b> {{'money immediately in
                        your'|translate}} <b style="color: #EAB308;">{{'main wallet.'|translate}}</b></p>

                    <img loading="lazy" style="margin: 0; width: 300px;z-index: 1000; margin-top: 50px;"
                        src="../../../../assets/images/what.png" alt="">

                    <div style="
               background: gray;
               position: relative;
               top: -28px;
               z-index: -1;
               width: 250px;
               margin: auto;
               height: 200px;
               border-radius: 20px;
               border-radius: 16px;
               background: rgba(255, 255, 255, 0.20);
               filter: blur(2px);
               ">
                    </div>
                    <img loading="lazy" style="    height: 200px;
                    position: relative;
                    top: -230px;
                " src="../../../../assets/images/Group 34374.svg" alt="">
                </div>


                <h1 style="font-weight: 600;">{{'Play with free money to keep increasing your winning
                    amount'|translate}}</h1>

                <h2 style="color: #7E8A98; font-weight: 400;">{{'Withdraw your winnings anytime*.'|translate}}</h2>

                <img loading="lazy" src="../../../../../assets/images/bonuses/moneyWallet.svg" alt="">

                <h2 style="color: #7E8A98; font-weight: 400;">{{'*Withdrawal will lead to cancellation of free money
                    from the wallet.'|translate}}</h2>

                <div class="darkgraybox">
                    <img loading="lazy" src="../../../../assets/logo.png" alt="">
                    <!-- <ul [innerHTML]="sanitizeInfoRules">

                    </ul> -->

                    <!-- {{sanitizeInfoRules}} -->
                    <ul>
                        <li>
                            <img loading="lazy" src="../../../../assets/images/arrow-right.svg" alt=""><br>
                            {{"Once claimed, the free money bonus amount will be transferred to the customer's Main
                            wallet immediately."|translate}}
                        </li>
                        <li>
                            <img loading="lazy" src="../../../../assets/images/arrow-right.svg" alt=""><br>
                            {{'The company has the right to amend the terms of the offer, cancel or renew the request,
                            or refuse to allow participation at any time without prior notice.'|translate}}
                        </li>
                        <li>
                            <img loading="lazy" src="../../../../assets/images/arrow-right.svg" alt=""><br>
                            {{'The bonus is deemed to have been wagered only after all the bets for the specified amount
                            have been settled.'|translate}}
                        </li>
                        <li>
                            <img loading="lazy" src="../../../../assets/images/arrow-right.svg" alt=""><br>
                            {{'Players found to have multiple accounts to abuse the bonus system may have their bonuses
                            and winnings voided.'|translate}}
                        </li>

                    </ul>

                    <!-- <div class="green-bold" [innerHTML]="sanitizeInfoRules"></div>

                </div> -->

                </div>
            </div>

        </div>
    </div>