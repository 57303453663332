<div class="loyalty-page">
    <div class="ios-header">
        <img [routerLink]="['/']" class="logo" src="../../../assets/logo.png" alt="Betkings">
        <svg (click)="closeLoyaltyPopup()" xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25"
            fill="none">
            <path
                d="M6.22578 19.3251L5.17578 18.2751L10.9508 12.5L5.17578 6.72505L6.22578 5.67505L12.0008 11.45L17.7758 5.67505L18.8258 6.72505L13.0508 12.5L18.8258 18.2751L17.7758 19.3251L12.0008 13.55L6.22578 19.3251Z"
                fill="#B6BECD" />
        </svg>
    </div>

    <div class="ios-back">
        <svg (click)="closeLoyaltyPopup()" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
            fill="none">
            <path
                d="M13.1213 17.0761L6.25 10.2047L13.1213 3.33337L14.0833 4.31254L8.19115 10.2047L14.0833 16.0969L13.1213 17.0761Z"
                fill="#003c79" />
        </svg>
        <span>{{'Loyalty Program'|translate}}</span>
    </div>

    <app-loyalty-popup *ngIf="showPopup" (closeClick)="showPopup = false"></app-loyalty-popup>

    <div class="ios-popup Modal-Background max-width-550">
        <div class="loyalty-card-section">
            <div class="loyalty-card">
                <div class="loyalty-body">
                    <div class="loyalty-first">
                        <img src="../../../../assets/images/loylty-logo-white.svg" alt="loyalty">
                    </div>
                    <div class="loyalty-text-box">
                        <span>{{'Beginner'|translate}} <small> {{'Next Level: Roller'|translate}}</small></span>
                        <div class="turnover-box">
                            <span>₹130/1000 {{'Turnover'|translate}}</span>
                        </div>
                    </div>
                </div>
                <svg (click)="openPopup()" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                    fill="none">
                    <path
                        d="M9.49375 13.75H10.6187V9.25H9.49375V13.75ZM9.99966 7.8625C10.1749 7.8625 10.3219 7.805 10.4406 7.69C10.5594 7.575 10.6187 7.4325 10.6187 7.2625C10.6187 7.08187 10.5595 6.93047 10.441 6.80828C10.3224 6.68609 10.1755 6.625 10.0003 6.625C9.82511 6.625 9.67813 6.68609 9.55938 6.80828C9.44063 6.93047 9.38125 7.08187 9.38125 7.2625C9.38125 7.4325 9.44051 7.575 9.55904 7.69C9.67758 7.805 9.82445 7.8625 9.99966 7.8625ZM10.005 17.5C8.97081 17.5 7.99894 17.3031 7.08936 16.9094C6.17979 16.5156 5.38438 15.9781 4.70312 15.2969C4.02188 14.6156 3.48438 13.8197 3.09063 12.9092C2.69687 11.9987 2.5 11.0259 2.5 9.99063C2.5 8.95539 2.69687 7.98252 3.09063 7.07202C3.48438 6.16151 4.02188 5.36875 4.70312 4.69375C5.38438 4.01875 6.18026 3.48438 7.09077 3.09063C8.00127 2.69687 8.97414 2.5 10.0094 2.5C11.0446 2.5 12.0175 2.69687 12.928 3.09063C13.8385 3.48438 14.6313 4.01875 15.3063 4.69375C15.9813 5.36875 16.5156 6.1625 16.9094 7.075C17.3031 7.9875 17.5 8.96084 17.5 9.99501C17.5 11.0292 17.3031 12.0011 16.9094 12.9106C16.5156 13.8202 15.9813 14.6145 15.3063 15.2934C14.6313 15.9724 13.8375 16.5099 12.925 16.9059C12.0125 17.302 11.0392 17.5 10.005 17.5ZM10.0094 16.375C11.7781 16.375 13.2812 15.7531 14.5187 14.5094C15.7562 13.2656 16.375 11.7594 16.375 9.99063C16.375 8.22188 15.7574 6.71875 14.5223 5.48125C13.2871 4.24375 11.7797 3.625 10 3.625C8.2375 3.625 6.73438 4.24258 5.49062 5.47773C4.24687 6.71289 3.625 8.22031 3.625 10C3.625 11.7625 4.24687 13.2656 5.49062 14.5094C6.73438 15.7531 8.24062 16.375 10.0094 16.375Z"
                        fill="white" />
                </svg>
            </div>

            <div class="loyalty-card">
                <div class="loyalty-body">
                    <div class="loyalty-first">
                        <img src="../../../../assets/images/loylty-logo-white.svg" alt="loyalty">
                    </div>
                    <div class="loyalty-text-box">
                        <span>{{'Beginner'|translate}} <small> {{'Next Level: Roller'|translate}}</small></span>
                        <div class="turnover-box">
                            <span>₹130/1000 {{'Turnover'|translate}}</span>
                        </div>
                    </div>
                </div>
                <svg (click)="openPopup()" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                    fill="none">
                    <path
                        d="M9.49375 13.75H10.6187V9.25H9.49375V13.75ZM9.99966 7.8625C10.1749 7.8625 10.3219 7.805 10.4406 7.69C10.5594 7.575 10.6187 7.4325 10.6187 7.2625C10.6187 7.08187 10.5595 6.93047 10.441 6.80828C10.3224 6.68609 10.1755 6.625 10.0003 6.625C9.82511 6.625 9.67813 6.68609 9.55938 6.80828C9.44063 6.93047 9.38125 7.08187 9.38125 7.2625C9.38125 7.4325 9.44051 7.575 9.55904 7.69C9.67758 7.805 9.82445 7.8625 9.99966 7.8625ZM10.005 17.5C8.97081 17.5 7.99894 17.3031 7.08936 16.9094C6.17979 16.5156 5.38438 15.9781 4.70312 15.2969C4.02188 14.6156 3.48438 13.8197 3.09063 12.9092C2.69687 11.9987 2.5 11.0259 2.5 9.99063C2.5 8.95539 2.69687 7.98252 3.09063 7.07202C3.48438 6.16151 4.02188 5.36875 4.70312 4.69375C5.38438 4.01875 6.18026 3.48438 7.09077 3.09063C8.00127 2.69687 8.97414 2.5 10.0094 2.5C11.0446 2.5 12.0175 2.69687 12.928 3.09063C13.8385 3.48438 14.6313 4.01875 15.3063 4.69375C15.9813 5.36875 16.5156 6.1625 16.9094 7.075C17.3031 7.9875 17.5 8.96084 17.5 9.99501C17.5 11.0292 17.3031 12.0011 16.9094 12.9106C16.5156 13.8202 15.9813 14.6145 15.3063 15.2934C14.6313 15.9724 13.8375 16.5099 12.925 16.9059C12.0125 17.302 11.0392 17.5 10.005 17.5ZM10.0094 16.375C11.7781 16.375 13.2812 15.7531 14.5187 14.5094C15.7562 13.2656 16.375 11.7594 16.375 9.99063C16.375 8.22188 15.7574 6.71875 14.5223 5.48125C13.2871 4.24375 11.7797 3.625 10 3.625C8.2375 3.625 6.73438 4.24258 5.49062 5.47773C4.24687 6.71289 3.625 8.22031 3.625 10C3.625 11.7625 4.24687 13.2656 5.49062 14.5094C6.73438 15.7531 8.24062 16.375 10.0094 16.375Z"
                        fill="white" />
                </svg>
            </div>
        </div>
        <div class="sport-box">
            <div class="sports-section">
                <div class="sports-card-header cricket-header">
                    <div class="one cricket-1">
                    </div>
                    <div class="header-logo">
                        <span class="inplay">{{'What is Loyalty Program?'|translate}}</span>
                    </div>
                </div>
                <div class="loyalty-text">
                    <span>{{"Betkings loyalty program is a unique opportunity to increase your earned prizes! All you
                        have to do is register and continue betting on the platform as you did before. The most active
                        players are always noticed by the company, so everything will be up to you. There are several
                        levels, which depend on how many points you have earned."|translate}}
                    </span>
                </div>
            </div>
            <div class="sports-section">
                <div class="sports-card-header cricket-header">
                    <div class="one cricket-1">
                    </div>
                    <div class="header-logo">
                        <span class="inplay">{{'Rules & Regulations'|translate}}</span>
                    </div>
                </div>
                <div class="loyalty-text">
                    <span>When participating in the Betkings Loyalty Program, every player must know some simple rules.

                        These include:
                        All bonuses depend only on your loyalty level, which means there is no limit to their amount;
                        The company has the right to downgrade you to your previous level if the average turnover is not
                        maintained for several months;
                        the bookmaker reserves the right to terminate the promotions offered or to change the rules for
                        their use without prior notice;
                        According to the terms and conditions, you may increase your loyalty status after meeting the
                        turnover requirements;
                        The company may cancel the prizes if you make more than 3 withdrawals in a week;
                        Bonus will not be credited if you bet the same amount of money.

                        Thus, when taking part in the loyalty program, you must comply with the above requirements to
                        play comfortably. In the corresponding section of the official site, you can also read the basic
                        rules and regulations.
                    </span>
                </div>
            </div>
            <div class="sports-section">
                <div class="sports-card-header cricket-header">
                    <div class="one cricket-1">
                    </div>
                    <div class="header-logo">
                        <span class="inplay">How to Become a Part of the Betkings Loyalty Program?</span>
                    </div>
                </div>
                <div class="loyalty-text">
                    <span>It’s a lot easier than you might imagine. In addition, this is a unique opportunity to
                        increase your rewards. It’s completely free to participate. All you have to do is register and
                        you will become part of the Betkings Loyalty Program. To do this, follow the step-by-step
                        instruction below:</span>
                </div>
            </div>
        </div>
    </div>
</div>